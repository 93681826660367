import React from 'react';

export function ProjectPageComponent(props) {

    return (
        <div>
            a Project page
        </div>
    );
}

export default ProjectPageComponent;